<template>
    <div>
  
        <template v-if="setting.type === 'text'">
            <RichText v-model="data[setting.id]" :format="false" :variable="false" />
        </template>
        <template v-else-if="setting.type === 'linkText'">
            <a-input v-model="data[setting.id]" />
        </template>
        <template v-else-if="setting.type === 'range'">
			<a-slider :vertical="Boolean(setting.vertical)" :style="`height:${setting.vertical? '100px' : 'auto'}`" :tipFormatter="e => setting.suffix ?  e + setting.suffix : e" v-model="sliderValue" @change="updateSlider" :min="setting.min || 1" :max="setting.max || 100" />
		</template>
        <template v-else-if="setting.type === 'options' && setting.visual">
            <VisualSelect v-model="data[setting.id]" :options="setting.options" :title="setting.optionTitle || setting.label" />
        </template>
        <template v-else-if="setting.type === 'options'">
            <a-select v-model="data[setting.id]" :options="setting.options" />
        </template>
        <template v-else-if="setting.type === 'logoColor'">
            <a-select v-model="data[setting.id]" :options="[{label:'Dark Logo',value:'dark'},{label:'Light Logo',value:'light'}]" />
        </template>
        <template v-else-if="setting.type === 'font'">
            <a-select v-model="data[setting.id]" :options="fontList" />
        </template>
        <template v-else-if="setting.type === 'switch' || setting.type === 'checkbox' || setting.type === 'boolean'">
            <a-switch v-model="data[setting.id]" />
        </template>
        <template v-else-if="setting.type === 'image' || setting.type === 'video'">
            <ImageBoxSelector v-model="data[setting.id]" :type="setting.type"  />
        </template>
        <template v-else-if="setting.type === 'instance'">
            <InstanceSelector v-model="data[setting.id]" />
        </template>
        <template v-else-if="setting.type === 'image-with-alt'">
            <ImageBoxAlt v-model="data[setting.id]" />
        </template>
        <template v-else-if="setting.type === 'gallery'">
            <GallerySet v-model="data[setting.id]" />
        </template>
		<template v-else-if="setting.type === 'galleryGroup'">
            <GroupGallerySet v-model="data[setting.id]" />
        </template>
        <template v-else-if="setting.type === 'modelConfig'">
            <ModelConfigurator v-model="data[setting.id]" />
        </template>
        <template v-else-if="setting.type === 'images'">
            <ImageBoxSelectorGroup v-model="data[setting.id]" :setting="setting" />
        </template>
        <template v-else-if="setting.type === 'richtext'">
            <RichText v-model="data[setting.id]" :format="true" :variable="false" :options="{bullets:Boolean(setting.bullets),sizeToggle:Boolean(setting.sizeToggle)}" />
        </template>
        <template v-else-if="setting.type === 'color'">
            <template v-if="$store.state.appData.builder && $store.state.appData.builder.currProject && $store.state.appData.builder.currProject.colors && $store.state.appData.builder.currProject.colors._theme">
                <ColorPicker v-model="data[setting.id]" :defaults="$store.state.appData.builder.currProject.colors._theme"/>
            </template>
            <template v-else>
                <ColorPicker v-model="data[setting.id]" />
            </template>
        </template>
        <template v-else-if="setting.type === 'button'">
            <ButtonEditor v-model="data[setting.id]" />
        </template>
        <template v-else-if="setting.type === 'link'">
            <LinkEditor v-model="data[setting.id]" />
        </template>
        <template v-else-if="setting.type === 'touchlink'">
            <LinkEditor v-model="data[setting.id]" type="touch" />
        </template>
        <template v-else-if="setting.type === 'features'">
            <FeaturesEditor v-model="data[setting.id]" />
        </template>
        <template v-else-if="setting.type === 'icon'">
             <IconSelect v-model="data[setting.id]" :url="Boolean(setting.url)" />
        </template>
        <template v-else-if="setting.type === 'tags' || setting.type === 'tag'">
             <TagSelect v-model="data[setting.id]" :multiple="setting.type === 'tags'" :from="setting.from" />
        </template>
		<template v-else-if="setting.type === 'translations'">
			<TranslationSettings v-model="data[setting.id]" />
        </template>
    </div>
</template>

<script>
import {setProp} from 'bh-mod'

export default {
    name:'SectionEditor',
    props:['data','setting','font-list'],
    components:{
        IconSelect: () => import('@/components/common/IconSelect'),
        VisualSelect: () => import('@/components/site/VisualSelect'),
        ImageBoxSelector: () => import('bh-mod/components/common/ImageBoxSelector'),
        ImageBoxAlt: () => import (`bh-mod/components/common/ImageBoxAlt`),
        ImageBoxSelectorGroup: () => import('@/components/site/ImageBoxSelectorGroup'),
        RichText: () => import('bh-mod/components/common/RichText'),
        ColorPicker: () => import('@/components/site/ColorPicker'),
        ButtonEditor: () => import('@/components/site/ButtonEditor'),
        ModelConfigurator: () => import('@/components/site/ModelConfigurator'),
        GallerySet: () => import('@/components/site/GallerySet'),
		GroupGallerySet: () => import('@/components/site/GroupGallerySet'),
        LinkEditor: () => import('@/components/site/LinkEditor'),
        TagSelect: () => import('@/components/site/TagSelect'),
        FeaturesEditor: () => import('@/components/site/FeaturesEditor'),
        InstanceSelector: () => import('@/components/site/InstanceSelector'),
		TranslationSettings: () => import('@/components/site/TranslationSettings'),
    },
	data(){
		return{
			sliderValue:0
		}
	},
	methods:{
		updateSlider(val){
			this.data[this.setting.id] = val;
		}
	},
	mounted(){
		if(this.setting.type === 'range'){
			this.sliderValue = this.data[this.setting.id];
		}
	}
}
</script>

<style>

</style>